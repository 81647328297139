fetch('build-info.json').then(data => data.json()).then(({ releaseDate, version }) => {
  const downloadLink = `download/app-production-releaseChina-bitrise-signed.apk`

  const $version = document.querySelector('#version')
  const $releaseDate = document.querySelector('#release-date')
  const downloadButtons = document.querySelectorAll('.download-button')

  $version.innerHTML = version
  $releaseDate.innerHTML = releaseDate
  downloadButtons.forEach(button => {
    button.href = downloadLink
  })
})
